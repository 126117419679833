<template lang="">
  <div class="flex flex-col h-full max-w-7xl mx-auto">
    <h1>Suche von Dateien</h1>
    
    <p v-show="searchResultAvaiable" class="italic mb-1">Suchergebnisse von "{{ searchObject.searchText }}"</p>
    <p v-show="searchError" class="mt-4 text-red-500">{{ searchError }}</p>

    <div v-if="searchObject.loading">        
      <div class="flex w-full justify-center mt-6">
        <font-awesome-icon
        icon="spinner"
        class="animate-spin text-primary text-3xl"
      />  
      </div>
    </div>

    <file-manager v-show="searchResultAvaiable" class="flex-grow min-h-0">
      <div class="grid grid-cols-2 gap-4 bg-primary p-2 text-gray-50">
        <div>Speicherort</div>
        <div>Name</div>
      </div>
      <div
        v-for="(item, index) in searchObject.result"
        :key="item" 
        class="bg-white" 
        :class="{ 'bg-gray-50' : index % 2 }"
      >
        <file-container :file="item">
          <div class="grid grid-cols-2 gap-4 p-2 break-all">
            <div>{{ getVaultNameById(item.vaultId) }}</div>
            <div>{{ item.name }}</div>
          </div>
        </file-container>
      </div>
    </file-manager>
  </div>
</template>
<script>
import FileManager from '@/components/fileManager/fileManager.vue'
import FileContainer from '@/components/fileManager/fileContainer.vue'
import _find from 'lodash/find'
import _debounce from 'lodash/debounce'
import _sortBy from 'lodash/sortBy'

export default {
  props: {
    search: { type: String, required: true },
  },

  components: {
    FileManager,
    FileContainer,
  },

  created() {
    this.searchForResults()
  },

  watch: {
    "$root.search": _debounce(function () {
      this.searchForResults()
    }, 1000)
  },

  computed: {
    searchResultAvaiable(){
      return !this.searchError && !this.searchObject.loading && (this.searchObject.result && this.searchObject.result.length)
    },

    searchError(){
      if(!this.searchValidationRegex.test(this.$root.search)){
        return "Bitte geben Sie mindestens 3 Zeichen (ohne besondere Suchzeichen) in die Suche ein"
      }else if(!this.searchObject.loading && (!this.searchObject.result || !this.searchObject.result.length)){
        return "Es konnten leider keine Suchergebnisse gefunden werden"
      }
      return ""
    },

    vaults(){
      return this.$store.state.vaults.vaults
    },
  },

  beforeUnmount() {
    this.$root.search = ""
  },

  data() {
    return {
      searchObject: {
        searchId: null,
        totalCount: null,
        result: null,
        loading: false,
        searchText: "",
      },
      searchValidationRegex: new RegExp(/(.*[a-z0-9]){3}/i)
    }
  },

  methods: {
    searchForResults(){
        if(!this.searchValidationRegex.test(this.$root.search)){
          return
        }

        this.searchObject.loading = true
        this.$api.request(`/documents/search`, 'post', {
          'searchText': this.$root.search,
        }).then((response) => {
          this.searchObject.searchId = response.data.searchId
          this.searchObject.totalCount = response.data.totalCount

        this.$api.request(`/documents/search/${this.searchObject.searchId}/results`, 'get', {
          "count": 500
        })
          .then((response) => {
            this.searchObject.searchText = this.$root.search
            this.searchObject.result = _sortBy(response.data, item => item.name)
            this.searchObject.loading = false
          })
        })
    },

    getVaultNameById(vaultId){
      const vault = _find(this.vaults, vault => vault.id === vaultId)
      if(vault){
        return vault.name
      }
      return "Unbekannter Speicherort"
    }
  },
}
</script>
<style lang="">
  
</style>